import configs from '@/web.config.ts';
import { jsonParser } from './utils';

const tokenKey = `${configs.prefix}_token`; // token名称

export default {
  get: (key: string) => jsonParser(window.localStorage.getItem(`${configs.prefix}_${key}`)), // 获取本地内容

  set: (key: string, data: unknown) => window.localStorage.setItem(`${configs.prefix}_${key}`, JSON.stringify(data)), // 设置本地内容

  remove: (key: string) => window.localStorage.removeItem(`${configs.prefix}_${key}`), // 移除本地内容

  tokenGet: () => window.localStorage.getItem(tokenKey), // 获取token

  tokenSet: (data: string) => window.localStorage.setItem(tokenKey, data), // 设置token

  tokenRemove: () => window.localStorage.removeItem(tokenKey), // 移除token
};
