import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import EmptyImage from '@/assets/image/null.png';
import footerButtons from '@/components/footerButtons/index.vue';
import breadcrumb from '@/components/breadcrumb/index.vue';
import triUi from 'tri-view';

import '@/promission';
import 'element-ui/lib/theme-chalk/index.css';
import 'tri-view/packages/styles/style.scss';
import '../src/style/reset.scss';
import '@/components/index.js';

Vue.use(ElementUI);
Vue.use(triUi);
Vue.component('footerButtons', footerButtons);
Vue.component('baseBreadcrumb', breadcrumb);

Vue.prototype.$TRIVIEW = {
  table: {
    empty: { text: '暂无数据', image: EmptyImage },
    thStyle: { color: '#333333', padding: '8px 0', background: '#F3F3F3' },
  },
};

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

// import JsonViewer from 'vue-json-viewer';
// Vue.use(JsonViewer);
