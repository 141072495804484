export const takeType = (data) => Object.prototype.toString.call(data).slice(8, -1); // Get the current data type

export const isDef = (data) => ['', null, undefined].indexOf(data) === -1; // Is the value defined

export const isArr = (data) => takeType(data) === 'Array'; // Is value an array

export const isObj = (data) => takeType(data) === 'Object'; // Is the value an object

export const isStr = (data) => typeof data === 'string'; // Is the value an String

export const isNum = (data) => typeof data === 'number'; // Is the value an Number

export const isBool = (data) => typeof data === 'boolean'; // Is the value an Boolean

export const isFunc = (data) => takeType(data) === 'Function'; // Is the value an Function

export const isDate = (data) => /^\d{4}-\d{2}-\d{2}$/.test(data);

export const isTime = (data) => /^\d{2}:\d{2}:\d{2}$/.test(data);

export const isUndef = (data) => ['', null, undefined].includes(data); // Is the value undefined

export const isPromise = (data) => typeof data === 'object' && typeof data.then === 'function'; // Is the value an Promise

export const isDatetime = (data) => /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(data);

export const isEmptyObj = (data) => isObj(data) && Object.keys(data).length === 0;

export const isObjOrArr = (data) => ['Array', 'Object'].includes(takeType(data));

export const isExternal = (data) => /^(https?:|mailto:|tel:)/.test(data);

export function throttle(func, delay) {
  let previous = Date.now();
  return (...args) => {
    const timestamp = Date.now();
    if (timestamp - previous > delay) {
      func.apply(this, args);
      previous = timestamp;
    }
  };
}

export default {
  isDef,
  isArr,
  isObj,
  isStr,
  isNum,
  isBool,
  isDate,
  isTime,
  isFunc,
  isUndef,
  isPromise,
  isDatetime,
  isEmptyObj,
  isObjOrArr,
  isExternal,
  takeType,
  throttle,
};
