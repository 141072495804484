
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class extends Vue {
  @Prop({ default: '40%' }) width?: string
  @Prop() iptText?: string
  @Prop({ default: false }) aloneInput?: boolean
  @Prop() aloneSelectOption?: Record<string, any>
  @Prop({ default: true }) isClean?: boolean
  @Prop({ default: '清除' }) buttonText?: string
  @Prop() buttonStyle?: string
  @Prop({ default: true }) buttonShow?: boolean


  selectValue = ''
  aloneSelectValue = '全部类型'
}
