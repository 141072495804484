import request from './request';

function setContentType(type: string) {
  if (type === 'json') return { 'Content-Type': 'application/json;charset=UTF-8' };
  if (type === 'form') return { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' };
  return { 'Content-Type': 'multipart/form-data' };
}

export default {
  /**
   * GET请求获取数据
   * @param {*} api 接口
   * @param {*} data 参数
   * @param {*} type 请求类型
   */
  get: (api: string, params = {}, type = 'json') => request({ url: api, params, method: 'GET', headers: setContentType(type) }),

  /**
   * POST请求提交数据
   * @param {*} api 接口
   * @param {*} data 数据
   * @param {*} type 请求类型
   */
  post: (api: string, body = {}, type = 'json') => request({ url: api, data: body, method: 'POST', headers: setContentType(type) }),

  /**
   * PUT请求更新数据
   * @param {*} api 接口
   * @param {*} data 数据
   * @param {*} type 请求类型
   */
  put: (api: string, body = {}, type = 'json') => request({ url: api, data: body, method: 'PUT', headers: setContentType(type) }),

  /**
   * PUT请求更新数据
   * @param {*} api 接口
   * @param {*} data 数据
   * @param {*} type 请求类型
   */
  patch: (api: string, body = {}, type = 'json') => request({ url: api, data: body, method: 'PATCH', headers: setContentType(type) }),

  /**
   * DELETE请求删除数据
   * @param {*} api 接口
   * @param {*} type 请求类型
   */
  delete: (api: string, type = 'json') => request({ url: api, method: 'DELETE', headers: setContentType(type) }),

  /**
   * POST请求导出数据
   * @param {*} api 接口
   * @param {*} data 参数
   * @param {*} type 请求类型
   */
  uploadblob: (api: string, data = {}) => request({ url: api, data: data, method: 'POST', responseType: 'blob' }),
};
