import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';

Vue.use(Vuex);

export default new Vuex.Store({
  state: { title: '物联云平台' },
  getters: {
    token: (state: any) => state.user.token,
  },
  mutations: {},
  actions: {},
  modules: { user },
});
