import router from '@/router';
import storage from '@/utils/storage';
import userApi from '@/api/login'; // 用户API
import { VuexState, LoginData } from '@/types/user';
import { Message } from 'element-ui';

export default {
  state: {
    token: storage.tokenGet() || '',
    userInfo: storage.get('userInfo'),
  },

  mutations: {
    SET_TOKEN: (state: VuexState, data: string) => (state.token = data), // 设置token

    SET_USERINFO: (state: VuexState, data: unknown) => (state.userInfo = data), // 设置是否已登录
  },

  // 异步操作
  actions: {
    // 登陆操作
    async Login({ dispatch }: any, form: LoginData) {
      const { code, data, msg }: any = await userApi.login(form);
      if (code !== 200) return Promise.reject(msg);
      await dispatch('GetInfo', data);
      Message.success('登录成功！');
      router.push('/');
    },

    // 退出登陆
    async Logout({ commit, dispatch }: any) {
      const { code }: any = await dispatch('RemoveToken');
      if (code == 200) {
        commit('SET_USERINFO', {});
        storage.remove('userInfo');
        Message.success('成功退出登陆！');
        setTimeout(() => router.push('/login'), 500);
      }
    },

    // 获取用户信息
    async GetInfo({ commit }: any) {
      const { code, data, msg }: any = await userApi.account();
      if (code !== 200) return Promise.reject(msg);
      commit('SET_USERINFO', data);
    },

    // 设置token
    SetToken({ commit }: any, value: string) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', value);
        storage.set('token', value);
        return resolve(value);
      });
    },

    // 移除token
    RemoveToken({ commit }: any) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '');
        storage.tokenRemove();
        return resolve({ code: 200 });
      });
    },
  },
  namespaced: true,
};
