import { isUndef } from './assert';

/**
 *
 * @param {*} data 需要格式化的数据
 * @param {*} init
 * @returns
 */
export function jsonParser(data: unknown, value = {}) {
  if (isUndef(data)) return value; // 无效值
  if (typeof data !== 'string') return data; // 其他值

  try {
    return JSON.parse(data);
  } catch (error) {
    return data;
  }
}
