
import TopTabs from './components/tabs.vue';
import { Component, Vue } from 'vue-property-decorator';
import { RouteRecord, RouteConfig } from 'vue-router';

@Component({ components: { TopTabs } })
export default class extends Vue {
  user = { name: '超级管理员', role: 'VIP', shape: 'radius' };
  title = { collapse: '物联云平台', expand: '物联云平台' };
  logo = { collapse: '', expand: require('@/assets/logo.png') };
  menus = [];
  header = { height: '80px', background: '#fff' };
  sidebar = { width: '220px', collapseWidth: '80px', textColor: '#fff', textColorActive: '#f00', backgroundColor: '#111224' };

  collapse = false;
  navbarButtons = [{ emit: 'link', icon: '', label: '切换演示数据' }];
  dropdownMenus = [
    { emit: 'link', label: '跳转大屏', divided: false },
    { emit: 'logout', label: '退出登录', divided: true },
  ];

  get routerArr() {
    return this.$router.options.routes;
  }

  created() {}

  get menuList() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { routes }: any = this;
    const match: RouteRecord[] = this.$route.matched;
    const father: RouteRecord = match[0];
    const target: RouteConfig | undefined = routes.find((item: RouteConfig) => item.name === father.name);
    if (!target) return [];
    return target.children || [];
  }

  // 退出登陆
  handleLogout() {
    this.$store.dispatch('user/Logout').catch((err: string) => console.log(err));
  }
}
