import store from './store';
import router from './router';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style

const whiteList = ['/login']; // no redirect whitelist
const setWebTitle = (title: string, name = '') => (document.title = [title, name].join('-'));

NProgress.configure({ showSpinner: false }); // NProgress Configuration

router.beforeEach(async (to: any, from, next) => {
  NProgress.start();
  const title = to.meta.title;
  const state = store.state;
  setWebTitle(title, state.title); // 设置标签名称
  if (store.getters.token) {
    if (to.path === '/login') {
      next({ path: '/' });
      NProgress.done();
    } else {
      next();
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next(`/login?redirect=${to.path}`);
      NProgress.done();
    }
  }
});

router.afterEach(() => NProgress.done());
