import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import store from '@/store';
import router from '@/router';
import { Message, MessageBox } from 'element-ui';
import { isDef } from './assert';

const baseURL = process.env.VUE_APP_BASE_API || ''; // 基础路径
const invalidToken = [500, 501, 502, 505, 504]; // 登陆失效code

// 创建实例
const instance = axios.create({
  baseURL: baseURL, // api的base_url
  timeout: 10000, // 请求超时时间
  withCredentials: true,
});

// 错误扑捉
const catchError = (error: AxiosError) => {
  console.log(error);
  console.log('err:' + error); // for debug
  Message({ message: error.message, type: 'error', duration: 2 * 1000 });
  return Promise.reject(error);
};

// 请求拦截
instance.interceptors.request.use((config: AxiosRequestConfig) => {
  if (store.getters.token && config.headers) {
    config.headers['Authorization'] = store.getters.token;
    config.headers['Access-Control-Allow-Origin'] = baseURL;
  }
  return config;
}, catchError);

// 响应拦截
instance.interceptors.response.use((response: AxiosResponse) => {
  const { data, headers, request } = response;
  const { responseType } = request;
  const { authorization } = headers;

  if (isDef(authorization)) store.dispatch('user/SetToken', authorization);
  if (responseType === 'blob') return response;

  if (invalidToken.includes(data.code)) {
    return MessageBox.confirm('登陆信息失效,请重新登陆', '提示', {
      type: 'warning',
      center: true,
      confirmButtonText: '确定',
    }).then(() => store.dispatch('user/RemoveToken').then(() => router.push('/login')));
  }
  return data;
}, catchError);

export default instance;
