import Layout from '@/layout/index.vue';
import transitionLayout from '@/layout/layout.vue';

export default {
  path: '/equipment',
  name: 'Equipment',
  redirect: '/equipment/overview',
  component: Layout,
  children: [
    {
      path: '/equipment/overview',
      name: 'EquipmentOverview',
      meta: { title: '概览', icon: 'zkfont-category' },
      component: () => import(/* webpackChunkName: "equipment" */ '@/views/equipment/overview/index.vue'),
    },
    {
      path: '/equipment/product',
      name: 'EquipmentProduct',
      meta: { title: '产品管理', icon: 'zkfont-product', breadcrumb: false },
      component: transitionLayout,
      redirect: '/equipment/product/list',
      children: [
        {
          path: '/equipment/product/list',
          name: 'EquipmentProductList',
          meta: { title: '产品管理' },
          component: () => import(/* webpackChunkName: "equipment" */ '@/views/equipment/product/list.vue'),
          hidden: true,
        },
        {
          path: '/equipment/product/sort',
          name: 'EquipmentProductSort',
          meta: { title: '产品分类' },
          component: () => import(/* webpackChunkName: "equipment" */ '@/views/equipment/product/sort.vue'),
          hidden: true,
        },
      ],
    },
    {
      path: '/equipment/device',
      name: 'EquipmentDevice',
      meta: { title: '设备管理', icon: 'zkfont-equipment' },
      component: () => import(/* webpackChunkName: "equipment" */ '@/views/equipment/device/index.vue'),
    },
    {
      path: '/equipment/physicalModel',
      name: 'EquipmentPhysicalModel',
      meta: { title: '物模型库', icon: 'zkfont-model' },
      component: () => import(/* webpackChunkName: "equipment" */ '@/views/equipment/physicalModel/index.vue'),
    },
    {
      path: '/equipment/createProduct',
      name: 'EquipmentCreateProduct',
      meta: { title: '物模型库', icon: 'el-icon-bell' },
      component: () => import(/* webpackChunkName: "equipment" */ '@/views/equipment/physicalModel/createProduct.vue'),
    },
    {
      path: '/equipment/directDevice',
      name: 'EquipmentDirectDevice',
      meta: { title: '直连设备接入', icon: 'zkfont-database' },
      component: transitionLayout,
      redirect: '/equipment/directDevice/index',
      children: [
        {
          path: '/equipment/directDevice/index',
          name: 'EquipmentDirectDeviceIndex',
          meta: { title: '直连设备接入' },
          hidden: true,
          component: () => import(/* webpackChunkName: "equipment" */ '@/views/equipment/device/device.vue'),
        },
        {
          path: '/equipment/directDevice/create',
          name: 'EquipmentDirectDeviceCreate',
          meta: { title: '创建产品' },
          hidden: true,
          component: () => import(/* webpackChunkName: "equipment" */ '@/views/equipment/device/create.vue'),
        },
      ],
    },
    {
      path: '/equipment/gatewayDevice',
      name: 'EquipmentGatewayDevice',
      component: transitionLayout,
      meta: { title: '网关设备接入', icon: 'zkfont-connect' },
      redirect: '/equipment/gatewayDevice/index',
      children: [
        {
          path: '/equipment/gatewayDevice/index',
          name: 'EquipmentGatewayDeviceIndex',
          meta: { title: '网关设备接入', icon: 'el-icon-bell' },
          component: () => import(/* webpackChunkName: "equipment" */ '@/views/equipment/device/device.vue'),
          hidden: true,
        },
        {
          path: '/equipment/gatewayDevice/create',
          name: 'EquipmentGatewayDeviceCreate',
          meta: { title: '创建产品', icon: 'el-icon-bell' },
          component: () => import(/* webpackChunkName: "equipment" */ '@/views/equipment/device/create.vue'),
          hidden: true,
        },
      ],
    },
    {
      path: '/equipment/edgeComputing',
      name: 'EquipmentEdgeComputing',
      meta: { title: 'X-Edge边缘计算', icon: 'zkfont-calculate' },
      redirect: '/equipment/edgeComputing/example',
      component: transitionLayout,
      children: [
        {
          path: '/equipment/edgeComputing/example',
          name: 'EquipmentEdgeExample',
          meta: { title: '边缘实例' },
          component: () => import(/* webpackChunkName: "equipment" */ '@/views/equipment/edgeComputing/example.vue'),
        },
        {
          path: '/equipment/edgeComputing/drive',
          name: 'EquipmentEdgeDrive',
          meta: { title: '边缘驱动' },
          component: () => import(/* webpackChunkName: "equipment" */ '@/views/equipment/edgeComputing/drive.vue'),
        },
        {
          path: '/equipment/edgeComputing/application',
          name: 'EquipmentEdgeApplication',
          meta: { title: '边缘应用' },
          component: () => import(/* webpackChunkName: "equipment" */ '@/views/equipment/edgeComputing/application.vue'),
        },
      ],
    },
    {
      path: '/equipment/lot',
      name: 'EquipmentLot',
      meta: { title: 'loT平台融合', icon: 'zkfont-combine', breadcrumb: false },
      component: transitionLayout,
      redirect: '/equipment/lot/list',
      children: [
        {
          path: '/equipment/lot/list',
          name: 'EquipmentLotList',
          component: () => import(/* webpackChunkName: "equipment" */ '@/views/equipment/loT/index.vue'),
          meta: { title: 'loT平台融合', icon: 'el-icon-bell' },
          hidden: true,
        },
        {
          path: '/equipment/configure',
          name: 'EquipmentLotConfigure',
          component: () => import(/* webpackChunkName: "equipment" */ '@/views/equipment/loT/configure.vue'),
          meta: { title: '配置详情', icon: 'el-icon-bell' },
          hidden: true,
        },
      ],
    },
    {
      path: '/equipment/yy-abutment',
      name: 'EquipmentYyAbutment',
      meta: { title: '云云对接', icon: 'zkfont-butting' },
      component: () => import(/* webpackChunkName: "equipment" */ '@/views/equipment/yyAbutment/index.vue'),
    },
    {
      path: '/equipment/certificate',
      name: 'EquipmentCertificate',
      meta: { title: '证书管理', icon: 'zkfont-certificate', breadcrumb: false },
      component: transitionLayout,
      redirect: '/equipment/certificate/list',
      children: [
        {
          path: '/equipment/certificate/list',
          name: 'EquipmentCertificateList',
          component: () => import(/* webpackChunkName: "equipment" */ '@/views/equipment/certificate/index.vue'),
          meta: { title: '证书管理', active: 'certificate' },
          hidden: true,
        },
        {
          path: '/equipment/certificate/relation',
          name: 'EquipmentCertificateCreate',
          component: () => import(/* webpackChunkName: "equipment" */ '@/views/equipment/certificate/relation.vue'),
          meta: { title: '添加证书', active: 'certificate' },
          hidden: true,
        },
      ],
    },
    {
      path: '/equipment/fittings',
      name: 'EquipmentFittings',
      meta: { title: '配件市场', icon: 'zkfont-market' },
      component: () => import(/* webpackChunkName: "equipment" */ '@/views/equipment/fittings/fittings.vue'),
    },
  ],
};
