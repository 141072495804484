import Layout from '../../layout/index.vue';
import transitionLayout from '@/layout/layout.vue';

export default {
  path: '/product',
  name: 'Product',
  component: Layout,
  redirect: '/product/workbatch',
  children: [
    {
      path: '/product/workbatch',
      name: 'ProductWorkbatch',
      meta: { title: '工作台', icon: 'zkfont-workbench' },
      component: () => import(/* webpackChunkName: "product" */ '@/views/product/workbatch/workbatch.vue'),
    },
    {
      path: '/product/message',
      name: 'ProductMessage',
      meta: { title: '产品信息', icon: 'zkfont-message' },
      component: () => import(/* webpackChunkName: "product" */ '@/views/product/message/message.vue'),
    },
    {
      path: '/product/register',
      name: 'ProductRegister',
      meta: { title: '设备注册', icon: 'zkfont-connect' },
      component: () => import(/* webpackChunkName: "product" */ '@/views/product/register/register.vue'),
    },
    {
      path: '/product/digital',
      name: 'ProductDigital',
      meta: { title: '数据解析', icon: 'zkfont-digital' },
      component: () => import(/* webpackChunkName: "product" */ '@/views/product/digital/digital.vue'),
    },
    {
      path: '/product/debugger',
      name: 'ProductDebugger',
      meta: { title: '设备调试', icon: 'zkfont-debugger' },
      component: () => import(/* webpackChunkName: "product" */ '@/views/product/debugger/debugger.vue'),
    },
    {
      path: '/product/model',
      name: 'ProductModel',
      meta: { title: '物模型', icon: 'zkfont-model' },
      component: transitionLayout,
      redirect: '/product/model/model',
      children: [
        {
          path: '/product/model/model',
          name: 'ProductModelList',
          meta: { title: '物模型', active: 'ProductModel', type: 'tab' },
          component: () => import(/* webpackChunkName: "product" */ '@/views/product/model/model.vue'),
        },
        {
          path: '/product/model/code',
          name: 'ProductModelCode',
          meta: { title: 'TML文件', active: 'ProductModel', type: 'tab' },
          component: () => import(/* webpackChunkName: "product" */ '@/views/product/model/code.vue'),
        },
      ],
    },
    {
      path: '/product/abnormal',
      name: 'ProductAbnormal',
      meta: { title: '异常设置', icon: 'zkfont-abnormal', breadcrumb: false },
      component: transitionLayout,
      redirect: '/product/abnormal/rule',
      children: [
        {
          path: '/product/abnormal/rule',
          name: 'ProductAbnormalRule',
          meta: { title: '异常规则', breadcrumb: false },
          component: transitionLayout,
          redirect: '/product/abnormal/product-rule',
          children: [
            {
              path: '/product/abnormal/product-rule',
              name: 'ProductAbnormalProductRule',
              meta: { title: '产品规则', active: 'ProductAbnormalRule', type: 'tab' },
              component: () => import('@/views/product/abnormal/product-rule.vue'),
            },
            {
              path: '/product/abnormal/device-rule',
              name: 'ProductAbnormalDeviceRule',
              meta: { title: '设备规则', active: 'ProductAbnormalRule', type: 'tab' },
              component: () => import('@/views/product/abnormal/device-rule.vue'),
            },
          ],
        },
        {
          path: '/product/abnormal/current',
          name: 'ProductAbnormalCurrent',
          meta: { title: '当前告警' },
          component: () => import('@/views/product/abnormal/current.vue'),
        },
        {
          path: '/product/abnormal/label',
          name: 'ProductAbnormalLabel',
          meta: { title: '等级标签' },
          component: () => import('@/views/product/abnormal/label.vue'),
          hidden: true,
        },
        {
          path: '/product/abnormal/history',
          name: 'ProductAbnormalHistory',
          meta: { title: '历史告警' },
          component: () => import('@/views/product/abnormal/history.vue'),
        },
      ],
    },
    {
      path: '/product/upgrade',
      name: 'ProductUpgrade',
      component: transitionLayout,
      meta: { title: '固件升级', icon: 'zkfont-upgrade', breadcrumb: false },
      redirect: '/product/upgrade/list',
      children: [
        {
          path: '/product/upgrade/task',
          name: 'ProductUpgradeTask',
          component: () => import('@/views/product/upgrade/task.vue'),
          meta: { title: '升级任务' },
        },
        {
          path: '/product/upgrade/list',
          name: 'ProductUpgradeList',
          component: () => import('@/views/product/upgrade/list.vue'),
          meta: { title: '固件列表' },
        },
      ],
    },
    {
      path: '/product/service',
      name: 'ProductService',
      meta: { title: '数据服务', icon: 'zkfont-service', breadcrumb: false },
      redirect: '/product/service/example',
      component: transitionLayout,
      children: [
        {
          path: '/product/service/snapshot',
          name: 'ProductServiceSnapshot',
          component: () => import('@/views/product/service/snapshot.vue'),
          meta: { title: '数据快照' },
        },
        {
          path: '/product/service/rule',
          name: 'ProductServiceRule',
          meta: { title: '统计规则', breadcrumb: false },
          component: transitionLayout,
          redirect: '/product/service/rule-device',
          children: [
            {
              path: '/product/service/rule-device',
              name: 'ProductServiceRuleDevice',
              component: () => import('@/views/product/service/rule-device.vue'),
              meta: { title: '设备统计', active: 'ProductServiceRule', type: 'tab' },
            },
            {
              path: '/product/service/rule-product',
              name: 'ProductServiceRuleProduct',
              component: () => import('@/views/product/service/rule-product.vue'),
              meta: { title: '产品统计', active: 'ProductServiceRule', type: 'tab' },
            },
          ],
        },
        {
          path: '/product/service/forward',
          name: 'ProductServiceForward',
          component: () => import('@/views/product/service/forward.vue'),
          meta: { title: '数据转发' },
        },
      ],
    },
    {
      path: '/product/record',
      name: 'ProductRecord',
      meta: { title: '设备日志', icon: 'zkfont-record' },
      component: () => import('@/views/product/record/record.vue'),
    },
  ],
};
